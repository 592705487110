import PropTypes from 'prop-types';
import { Checkbox, TableHead, TableRow, TableCell } from '@mui/material';
import { useContext } from 'react';
import { TableContext } from '../../App';

const headCells = [
  { id: 'ID', label: 'ID', width: 40 },
  { id: 'Shortcut', label: 'Shortcut', width: 120 },
  { id: 'URL', label: 'URL', width: 800 },
];

export function MainTableHeader(props) {
  const { onSelectAllClick, rowCount } = props;

  const { selected } = useContext(TableContext);

  const numSelected = selected.length;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding='normal'
            sx={{ width: headCell.width }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

MainTableHeader.propTypes = {
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
};
