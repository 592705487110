import { useState } from "react";

export function InitAPI() {
    const [urls, setUrls] = useState([]);
    const fetchData = async (user, pass) => await fetch('/api/urls', {
        headers: {
            Authorization: "Basic " + btoa(`${user}:${pass}`)
        }
    })
        .then((response) => response.json())
        .then((data) => {
            setUrls(data);
        });

    const postShortcut = async (shortcut, url, user, pass) => await fetch('/api/urls?ignoreDeleted=true', {
        method: 'POST',
        headers: {
            Authorization: "Basic " + btoa(`${user}:${pass}`),
            'Content-Type': 'application/json'
        },
        body: `{ "url": "${url}", "shortcut": "${shortcut}" }`
    })
        .then((response) => { })
        .then((data) => {
            fetchData(user, pass);
        })
        .catch((err) => {
            console.log(err);
        });

    const deleteShortcuts = async (IDs, user, pass) => {
        const deletions = [];
        IDs.forEach(async id => {
            deletions.push(fetch(`/api/urls/${id}`, {
                method: 'DELETE',
                headers: {
                    Authorization: "Basic " + btoa(`${user}:${pass}`),
                }
            })
                .then((response) => { })
                .then((data) => { })
                .catch((err) => {
                    console.log(err);
                }))
        });

        await Promise.all(deletions)
            .then(() => { fetchData(user, pass) });
    }

    return [urls, fetchData, postShortcut, deleteShortcuts]
}
